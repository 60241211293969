var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v(" Are the borrowers aged 55 or over at application or 75 years or over when the Home Loan matures? ")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.retirement.during_loan,
            callback: function callback($$v) {
              _vm.$set(_vm.retirement, "during_loan", $$v);
            },
            expression: "retirement.during_loan"
          }
        }, _vm._l(_vm.duringLoanOptions, function (duringLoan, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": duringLoan.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(duringLoan.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _vm.retirement.during_loan ? _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v(" How do you intend to repay the loan? ")])])];
            },
            proxy: true
          }], null, false, 2192200913),
          model: {
            value: _vm.retirement.repay,
            callback: function callback($$v) {
              _vm.$set(_vm.retirement, "repay", $$v);
            },
            expression: "retirement.repay"
          }
        }, _vm._l(_vm.repayOptions, function (repayLoan, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": repayLoan.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(repayLoan.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1) : _vm._e(), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v(" Does the applicant plan or anticipate changes (other than retirement) to their future financial circumstances that could ADVERSELY impact their ability to repay the loan? ")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.retirement.anticipate,
            callback: function callback($$v) {
              _vm.$set(_vm.retirement, "anticipate", $$v);
            },
            expression: "retirement.anticipate"
          }
        }, _vm._l(_vm.duringLoanOptions, function (duringLoan, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": duringLoan.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(duringLoan.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _vm.retirement.anticipate ? _c('v-textarea', {
          attrs: {
            "outlined": "",
            "label": "Comment",
            "placeholder": "Comment",
            "rows": 4,
            "auto-grow": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.retirement.comment,
            callback: function callback($$v) {
              _vm.$set(_vm.retirement, "comment", $$v);
            },
            expression: "retirement.comment"
          }
        }) : _vm._e()], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-radio-group
            v-model="retirement.during_loan"
            :row="true"
          >
            <template #label>
              <div>
                <h3>
                  Are the borrowers aged 55 or over at application or 75 years
                  or over when the Home Loan matures?
                </h3>
              </div>
            </template>
            <v-radio
              v-for="(duringLoan, idx) in duringLoanOptions"
              :value="duringLoan.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ duringLoan.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-radio-group
            v-model="retirement.repay"
            v-if="retirement.during_loan"
            :row="true"
          >
            <template #label>
              <div>
                <h3>
                  How do you intend to repay the loan?
                </h3>
              </div>
            </template>
            <v-radio
              v-for="(repayLoan, idx) in repayOptions"
              :value="repayLoan.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ repayLoan.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-radio-group
            v-model="retirement.anticipate"
            :row="true"
          >
            <template #label>
              <div>
                <h3>
                  Does the applicant plan or anticipate changes (other than retirement) to
                  their future financial circumstances that could ADVERSELY impact their
                  ability to repay the loan?
                </h3>
              </div>
            </template>
            <v-radio
              v-for="(duringLoan, idx) in duringLoanOptions"
              :value="duringLoan.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ duringLoan.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-textarea
            v-if="retirement.anticipate"
            outlined
            label="Comment"
            placeholder="Comment"
            :rows="4"
            auto-grow
            v-model="retirement.comment"
            :disabled="FIELDS_DISABLED"
          ></v-textarea>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'RetirementSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      duringLoanOptions: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],

      repayOptions: [
        {
          text: 'Co-applicant income',
          value: 'Co-applicant income',
        },
        {
          text: 'KiwiSaver',
          value: 'KiwiSaver',
        },
        {
          text: 'Downsize Home',
          value: 'Downsize Home',
        },
        {
          text: 'Sale of Assets',
          value: 'Sale of Assets',
        },
        {
          text: 'Superannuation',
          value: 'Superannuation',
        },
        {
          text: 'Income from Other Investment',
          value: 'Income from Other Investment',
        },
        {
          text: 'Savings',
          value: 'Savings',
        },
        {
          text: 'Repayment of Loan prior Retirement',
          value: 'Repayment of Loan prior Retirement',
        },
        {
          text: 'Other',
          value: 'Other',
        },
      ],

      isSectionVisible: false,

      initialRetirement: {},
      retirement: {},
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  watch: {
    'retirement.during_loan': {
      handler() {
        this.retirement.repay = null;
      },
    },
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialRetirement = clone(this.existedData);
    this.retirement = clone(this.existedData);

    this.$emit('componentReady');
  },
};
</script>
